// Public (older IE-compatible) Accordion

$(".public-accordion .content").each(function () {
  if ($(this).hasClass("active")) {
    $(this).prev().addClass("active");
    $(this).prev().find("i").addClass("rotated");
  }
});

$(".public-accordion .content").hide();
$(".public-accordion .content.active").show();

//initilise bits once eerythingis loaded
//or reinitalise if content loaded bia ajax
function initFunctionsPostAjax(container) {
  // Semantic UI JS needs to be reinitiated after ajax call as new elements introduced into DOM
  $.getScript("/js/semantic.min.js").done(function () {
    container.find(".ui.accordion").accordion();

    container.find(".ui.checkbox").checkbox();

    if (container.find("table.sortable").length) {
      container.find("table.sortable").tablesort();
    }

    container.find(".info-popup").popup();
  });
}

$(function () {
  /********************************************
    
    Mobile menu
    Clones main header nav and adds it to sidebar 

    *********************************************/

  $("header.user-header .menu.main-nav").clone(false).appendTo("#mobile-nav");
  $("#mobile-nav .main-nav")
    .removeClass("compact")
    .addClass("fluid vertical stacking centered");
  $("#mobile-nav .account-nav").removeClass("dropdown link");
  $("#mobile-nav .account-nav .menu")
    .insertAfter($("#mobile-nav .account-nav"))
    .find(".item")
    .css("font-size", "1rem");

  $(document).on("click", "#mob-menu", function (e) {
    $("#mobile-nav").sidebar("toggle");
  });

  /**************
     End mobile nav
    ***************/

  if ($("#myrnmenu .account-nav .menu .item.active").length) {
    $("#myrnmenu .account-nav").addClass("active-style");
  }

  $("#myrnmenu .account-nav").dropdown({
    on: "hover",
    allowReselection: true,
    action: "select" /*function(){
            // blank function to stop parent menu item text from temparaily changing before page load
        },*/,
    direction: "downward",
  });

  $(".public-accordion .title").click(function () {
    var $this = $(this);

    $this.addClass("active");
    $(this).find(".dropdown.icon").addClass("rotated");

    $this.addClass("active");
    $(this).find(".dropdown.icon").addClass("rotated");

    if ($this.next().hasClass("active")) {
      $this.next().removeClass("active");
      $this.next().slideUp(350);
      $this.removeClass("active");
      $this.find(".dropdown.icon").removeClass("rotated");
    } else {
      $this.parent().find("div .content").removeClass("active");
      $this.next().toggleClass("active");
      $this.next().slideToggle(350);
      $this.addClass("active");
      $this.find(".dropdown.icon").addClass("rotated");
    }
  });

  // Message close

  $(".message .close").on("click", function () {
    $(this).closest(".message").transition("fade");
  });

  // Dropdown menus

  $(
    '.ui.dropdown:not(.ui.dropdown.account-nav):not(.strava-multi-new):not(.strava-multi-existing):not(.multi):not(#charity[name="charity_id"])'
  ).dropdown();

  // Read More / Less Buttons

  $(document).on("click", ".read-more-btn", function (e) {
    $("#story").removeClass("limited-story");
    $(e.currentTarget).hide();
    $(".read-less-btn").show();
  });

  $(document).on("click", ".read-less-btn", function (e) {
    $("#story").addClass("limited-story");
    $(e.currentTarget).hide();
    $(".read-more-btn").show();
  });

  // Date picker (uses /public/js/bower_components/semantic-ui-calendar)

  $(".datetime-pick").calendar({
    type: "datetime",
    formatter: {
      datetime: function (strDate, settings) {
        if (strDate != undefined) {
          return $.format.date(strDate, "D MMM yyyy, h:mm a");
        }
      },
    },
  });

  $(".date-pick").calendar({
    type: "date",
    formatter: {
      date: function (strDate, settings) {
        if (strDate != undefined) {
          return $.format.date(strDate, "D MMM yyyy");
        }
      },
    },
  });

  $(".edit.button").on("click", function (ev) {
    ev.preventDefault();

    var $btn = $(this);
    var $content = $(this).prev(".content");

    $.get($btn.attr("href"), function (data) {
      $content.html($(data));
      $btn.hide();
    });
  });

  //New generic ajax Functions
  //@TODO abstract these 3 functions into 1 or make it DRY-er atleast

  //Generic Ajax On Load
  $(".ajaxOnLoad").each(function (e) {
    //retrieve the get URL from the data attribute
    //e.preventDefault();

    var thisElement = $(this);
    var containerId = thisElement.data("success-container");
    var container = $(containerId);
    var pageUrl = thisElement.data("page-url");

    thisElement.addClass("loading");

    if (
      thisElement.data("complete") === undefined ||
      !thisElement.data("complete")
    ) {
      $.ajax({
        url: pageUrl,
        dataType: "html",
        method: "GET",
        success: function (content) {
          if (thisElement.data("modal") === 1) {
            container.modal("show");
            container
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          } else {
            container.html(content);
          }

          //if it doesn't exists its because the container could be updated multiple times
          if (thisElement.data("complete") !== undefined) {
            thisElement.attr("data-complete", 1);
          }
        },
        error: function (content) {
          //display an error message in the error modal
          //.alert modal
          $(".ui.small.alert.modal").modal("show");
          $(".ui.small.alert.modal")
            .find(".content")
            .addClass("rn-dark ui centered")
            .html(content);
        },
        complete: function (content) {
          //remove loading icon and reenable button
          $(containerId).removeClass("loading");
          initFunctionsPostAjax($(containerId));
        },
      });
    }
  });

  //Generic Ajax On Load or On Click
  $(document).on("click", ".ajaxLoadOnClick", function (e) {
    //retrieve the get URL from the data attribute
    e.preventDefault();

    var thisElement = $(this);
    var containerId = thisElement.data("success-container");
    var container = $(containerId);
    var pageUrl = thisElement.data("page-url");

    thisElement.addClass("loading");

    if (
      thisElement.data("complete") === undefined ||
      !thisElement.data("complete")
    ) {
      $.ajax({
        url: pageUrl,
        dataType: "html",
        crossDomain: true,
        method: "GET",
        success: function (content) {
          if (thisElement.data("modal") === 1) {
            container.modal("show");
            container
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          } else {
            container.html(content);
          }

          //if it doesn't exists its because the container could be updated multiple times
          if (thisElement.data("complete") !== undefined) {
            thisElement.attr("data-complete", 1);
          }
        },
        error: function (content, data, message) {
          //display an error message in the error modal
          //.alert modal
          console.log(content, data, message);
          $(".ui.small.alert.modal").modal("show");
          $(".ui.small.alert.modal")
            .find(".content")
            .addClass("rn-dark ui centered")
            .html(message);
        },
        complete: function (content) {
          //remove loading icon and reenable button
          thisElement.removeClass("loading");
          initFunctionsPostAjax(container);
        },
      });
    }
  });

  $.ajaxSetup({
    cache: true,
  });

  //Generic Ajax On Load or On Click
  $(document).on("submit", "form.ajaxOnSubmit", function (e) {
    //retrieve the get URL from the data attribute
    e.preventDefault();

    var thisElement = $(this);
    var containerId = thisElement.data("success-container");
    var container = $(containerId);
    var submitUrl = thisElement.attr("action");

    thisElement.addClass("loading");

    thisElement
      .find('[type="submit"]')
      .addClass("loading")
      .prop("disabled", true);

    if (
      thisElement.data("complete") === undefined ||
      !thisElement.data("complete")
    ) {
      $.ajax({
        url: submitUrl,
        dataType: "html",
        method: "POST",
        data: "_token=" + csrftoken + "&" + thisElement.serialize(),
        success: function (content) {
          if (thisElement.data("modal") === 1) {
            container.modal("show");
            container
              .find(".content")
              .addClass("rn-dark ui centered")
              .html(content);
          } else {
            container.html(content);
          }

          //if it doesn't exists its because the container could be updated multiple times
          if (thisElement.data("complete") !== undefined) {
            thisElement.attr("data-complete", 1);
          }
        },
        error: function (content) {
          //display an error message in the error modal
          //.alert modal
          $(".ui.small.alert.modal").modal("show");
          $(".ui.small.alert.modal")
            .find(".content")
            .addClass("rn-dark ui centered")
            .html(content);
        },
        complete: function (content) {
          //remove loading icon and reenable button
          thisElement.removeClass("loading");
          initFunctionsPostAjax($(container));

          thisElement
            .find('[type="submit"]')
            .removeClass("loading")
            .prop("disabled", false);
        },
      });
    }
  });

  //END New generic ajax Functions

  $(".ajax_submit").on("click", function (ev) {
    ev.preventDefault();

    var $form = $(this).closest("form");
    var $content = $(this).closest(".content");
    var $btn = $content.next("a.edit");

    $.ajax({
      url: $form.attr("action"),
      dataType: "html",
      method: "PUT",
      data: "_token=" + csrftoken + "&" + $form.serialize(),
      success: function (content) {
        $content.html(content);
        $btn.show();
      },
      error: function (err) {
        $(".ui.small.alert.modal").modal("show");
        $(".ui.small.alert.modal")
          .find(".content")
          .addClass("rn-dark ui centered")
          .html(
            "<strong>Sorry, that didn't save properly, please try again.</strong>"
          );
        //alert("Sorry, that didn't save properly, please try again.");
        //console.log(err);
      },
    });
  });

  $(".cancel_membership_ajax").on("click", function (ev) {
    ev.preventDefault();

    var $form = $(this).closest("form");
    var $content = $(this).closest(".content");
    var $btn = $content.next("a.edit");

    $.ajax({
      url: $form.attr("action"),
      dataType: "html",
      method: "POST",
      data: "_token=" + csrftoken + "&" + $form.serialize(),
      headers: {
        Authorization: "Basic " + btoa("rnadmin:Love2ruN"),
      },
      success: function (content) {
        alert("Your membership has been cancelled.");
        location.reload();
      },
      error: function (content) {
        $(".ui.small.alert.modal").modal("show");
        $(".ui.small.alert.modal")
          .find(".content")
          .addClass("rn-dark ui centered")
          .html(
            "<strong>Sorry there was a problem cancelling your subscription, please try again or contact your club directly.</strong>"
          );
        //alert("Sorry there was a problem cancelling your subscription, please try again or contact your club support");
      },
    });
  });

  // Resuult button - open new tab on mobiles etc

  $(document).on("click", ".results-button", function (e) {
    if ($(window).width() >= 1024) {
      // if window is smaller than 1024, bypass timing views modal and open in new tab on device browser

      $("#results-modal")
        .modal({
          onShow: function () {
            $("#iframe-results").attr("src", $(e.currentTarget).data("src"));
            $("#iframe-results").on("load", function () {
              $("#text-link")
                .attr("href", $(e.currentTarget).data("src"))
                .show();
            });
          },
        })
        .modal("show");
    } else {
      window.open($(e.currentTarget).data("src"), "_blank");
    }
  });

  if ($(window).width() >= 1024) {
    if ($(".strava-time").length) {
      console.log($(window).width());
      $(".strava-time").attr("target", "_self");
    }
  }

  ////////////////////////////////////////////////////////////////////////

  $(".show-optional").change(function () {
    if ($(this).is(":checked")) {
      $(this)
        .closest("div.field")
        .parents()
        .find(".optional-field:hidden")
        .fadeIn();
    } else {
      $(this)
        .closest("div.field")
        .parents()
        .find(".optional-field:visible")
        .fadeOut();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", false);
      $(this)
        .closest("div.field")
        .find(
          '.optional-field input[type="text"], .optional-field input[type="number"]'
        )
        .val("");
    }
  });

  $(".hide-optional").change(function () {
    if ($(this).is(":checked")) {
      $(this).closest(".field").next(".optional-field:visible").fadeOut();
      $(this)
        .closest("div.field")
        .find('.optional-field input[type="checkbox"]')
        .prop("checked", false);
      $(this)
        .closest("div.field")
        .find(
          '.optional-field input[type="text"], .optional-field input[type="number"]'
        )
        .val("");
    } else {
      $(this).closest("div.field").next(".optional-field:hidden").fadeIn();
    }
  });

  // Semantic UI Quirk for displaying cached checboxes that hide other fields
  var hide_optional = $(".hide-optional").parent();
  if (hide_optional.checkbox("is checked")) {
    hide_optional.closest(".field").next(".optional-field:visible").hide();
    hide_optional
      .closest("div.field")
      .find('.optional-field input[type="checkbox"]')
      .prop("checked", false);
    hide_optional
      .closest("div.field")
      .find(
        '.optional-field input[type="text"], .optional-field input[type="number"]'
      )
      .val("");
  } else {
    hide_optional.closest(".field").next(".optional-field:hidden").show();
  }

  $(".confirm-alert").on("click", function (ev) {
    ev.preventDefault();
    $btn = $(this);

    $(".ui.small.modal.confirm .content > p").html($(this).data("question"));
    $(".ui.small.modal.confirm")
      .modal({
        blurring: true,
        closable: false,
        onDeny: function () {
          return true;
        },
        onApprove: function () {
          if ($btn.attr("href")) {
            window.location.href = $btn.attr("href");
          } else {
            $btn.closest("form").submit();
          }
        },
      })
      .modal("show");
  });

  $(".helper-action").on("click", function () {
    var helpTitle = $(this).parent().text();
    var helpText = $(this).attr("data-helper-text");

    $(
      '<div class="item"><div class="header">' +
        helpTitle +
        '</div><div class="half-spacer"></div>' +
        helpText +
        "</div>"
    ).prependTo(".ui.sidebar.tips .list");

    $(".ui.sidebar.tips")
      .sidebar("setting", "transition", "overlay")
      .sidebar("setting", "dimPage", "false")
      .sidebar("toggle");
  });

  // Clear the sidebar of its content when closed

  $(".ui.sidebar.tips").sidebar({
    onHidden: function () {
      $(".ui.sidebar.tips .list").html("");
    },
  });

  $("#close-tips").click(function () {
    $(".ui.sidebar.tips").sidebar("hide");
  });

  $(".ui.checkbox").checkbox();

  $("table.sortable").tablesort();

  $(".ui.accordion").accordion();

  $(".info-popup").popup();

  $(".my-rn-home .menu .item").tab();
});

// Message close

$(".message .close").on("click", function () {
  $(this).closest(".message").transition("fade");
});

// Dropdown menus

$(
  '.ui.dropdown:not(.ui.dropdown.account-nav):not(.strava-multi-new):not(.strava-multi-existing):not(.multi):not(#charity[name="charity_id"])'
).dropdown();

// Read More / Less Buttons

$(document).on("click", ".read-more-btn", function (e) {
  $("#story").removeClass("limited-story");
  $(e.currentTarget).hide();
  $(".read-less-btn").show();
});

$(document).on("click", ".read-less-btn", function (e) {
  $("#story").addClass("limited-story");
  $(e.currentTarget).hide();
  $(".read-more-btn").show();
});

// Date picker (uses /public/js/bower_components/semantic-ui-calendar)

$(".datetime-pick").calendar({
  type: "datetime",
  formatter: {
    datetime: function (strDate, settings) {
      if (strDate != undefined) {
        return $.format.date(strDate, "D MMM yyyy, h:mm a");
      }
    },
  },
});

$(".date-pick").calendar({
  type: "date",
  formatter: {
    date: function (strDate, settings) {
      if (strDate != undefined) {
        return $.format.date(strDate, "D MMM yyyy");
      }
    },
  },
});

$(".edit.button").on("click", function (ev) {
  ev.preventDefault();

  var $btn = $(this);
  var $content = $(this).prev(".content");

  $.get($btn.attr("href"), function (data) {
    $content.html($(data));
    $btn.hide();
  });
});

////////////////////////////////////////////////////////////////////////

$(".confirm-alert").on("click", function (ev) {
  ev.preventDefault();
  $btn = $(this);

  $(".ui.small.modal.confirm .content > p").html($(this).data("question"));
  $(".ui.small.modal.confirm")
    .modal({
      blurring: true,
      closable: false,
      onDeny: function () {
        return true;
      },
      onApprove: function () {
        if ($btn.attr("href")) {
          window.location.href = $btn.attr("href");
        } else {
          $btn.closest("form").submit();
        }
      },
    })
    .modal("show");
});

$(".helper-action").on("click", function () {
  var helpTitle = $(this).parent().text();
  var helpText = $(this).attr("data-helper-text");

  $(
    '<div class="item"><div class="header">' +
      helpTitle +
      '</div><div class="half-spacer"></div>' +
      helpText +
      "</div>"
  ).prependTo(".ui.sidebar.tips .list");

  $(".ui.sidebar.tips")
    .sidebar("setting", "transition", "overlay")
    .sidebar("setting", "dimPage", "false")
    .sidebar("toggle");
});

// Clear the sidebar of its content when closed

$(".ui.sidebar.tips").sidebar({
  onHidden: function () {
    $(".ui.sidebar.tips .list").html("");
  },
});

$("#close-tips").click(function () {
  $(".ui.sidebar.tips").sidebar("hide");
});

$(".ui.checkbox").checkbox();

$("table.sortable").tablesort();

$(".ui.accordion").accordion();

$(".info-popup").popup();

$(".my-rn-home .menu .item").tab();

// Meta description tidy-ups

if ($('meta[name="twitter:description"]').length) {
  var $desc = $('meta[name="twitter:description"]').attr("content");

  $desc = $.trim($desc.replace(/\s\s+/g, " "));

  $('meta[name="twitter:description"]').attr("content", $desc);
}

// Read more button on entry pages

$(".retract-event-details").hide();

$(function () {
  var event_desc = $(".event-desc");
  var event_desc_height = event_desc.height();

  event_desc.css({ height: "118px", overflow: "hidden" });

  $(".expand-event-details").click(function () {
    $(this).hide();
    event_desc.animate({ height: event_desc_height }, 250);
    $(".retract-event-details").show();
  });

  $(".retract-event-details").click(function () {
    $(this).hide();
    event_desc.animate({ height: "118px" }, 250);
    event_desc.css({ overflow: "hidden" });
    $(".expand-event-details").show();
  });
});

var title = $("h1.content, h1.sponsor-me")
  .contents()
  .not($(".sub.header"))
  .text();

// Get SG spon pages header colours if too light against white and apply alternative colour.

if ($(".border-wrapped-header").length) {
  var heading = $(".border-wrapped-header").css("color");
  var border = $(".border-wrapped").css("border-left-color");
  var lighterColour = false;

  //get r,g,b and decide
  var rgbh = heading
    .replace(/^(rgb|rgba)\(/, "")
    .replace(/\)$/, "")
    .replace(/\s/g, "")
    .split(",");
  var yiqh = (rgbh[0] * 299 + rgbh[1] * 587 + rgbh[2] * 114) / 1000;

  var rgbb = border
    .replace(/^(rgb|rgba)\(/, "")
    .replace(/\)$/, "")
    .replace(/\s/g, "")
    .split(",");
  var yiqb = (rgbb[0] * 299 + rgbb[1] * 587 + rgbb[2] * 114) / 1000;

  if (yiqh > 128) {
    lighterColour = true;
  } else {
    lighterColour = false;
  }

  $(
    ".public .border-wrapped-header, .public .border-wrapped, .public .public-container .sponsored, .public span.donations, .public span.target-total"
  ).each(function () {
    if (lighterColour == true) {
      $(this).addClass("lighter-colour");
    }
  });
}

//////////////////////// Numeric Validation for Number Fields //////////////////////////

function numericWithDecimalNew(inputValue, decimalLength, intLength) {
  // Fix bug in Firefox, convert input to type=text if user agent is FF and input value contains a decimal place
  if (
    $('input[type="number"]:focus').length &&
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1
  ) {
    var numberInputs = $('input[type="number"]:focus');

    if (numberInputs.val().indexOf(".")) {
      numberInputs.attr("type", "text");
      numberInputs[0].focus();
      numberInputs[0].setSelectionRange(
        numberInputs[0].value.length,
        numberInputs[0].value.length,
        "forward"
      ); //numberInputs[0].select()
    }
  } // Set default value for decimalLength - old way as we are not using ES2015.

  decimalLength = typeof decimalLength !== "undefined" ? decimalLength : 2; // Create a regular expression where we can pass in a variable.

  var expression = new RegExp("[^\\d.]", "g");
  var match_expression = new RegExp(
    "(\\d{0," + intLength + "})[^.]*((?:\\.\\d{0," + decimalLength + "})?)",
    "g"
  );
  match = match_expression.exec(inputValue.replace(expression, ""));
  return match[1] + match[2];
}
